<template>
	<div data-route>
		<page-header
			heading="Categories"
			:action="getHeaderAction"
		/>
		<div data-element="main">
			<table-view
				:table="getTable"
				:showSearch="false"
				:showPagination="false"
			/>
		</div>
	</div>
</template>

<script>

	import PageHeader  from '@/components/ui/PageHeader';
	import TableView   from '@/components/ui/views/tableView/TableView';
	import api         from '@/services/api';

	export default {
		metaInfo: {
			title: 'Categories'
		},
		components: {
			PageHeader,
			TableView
		},
		data: () => ({
			categories: []
		}),
		computed: {
			getTable () {
				return {
					headings: [
						'Title'
					],
					rows: this.categories.map((category) => {
						return {
							route: `/admin/knowledgebase/categories/${category.id}`,
							columns: [
								category.title
							]
						};
					})
				};
			},
			getHeaderAction () {
				return {
					text: 'Add category',
					route: '/admin/knowledgebase/categories/create'
				};
			},
			getBreadcrumbs () {
				return [
					{
						path: '/admin',
						text: 'Admin'
					},
					{
						path: '/admin/knowledgebase',
						text: 'Knowledgebase'
					},
					{
						path: '/admin/knowledgebase/categories',
						text: 'Categories'
					}
				].filter(Boolean);
			}
		},
		created: function () {
			this.setCategories();
		},
		methods: {
			async setCategories () {
				const categories = await api.admin.getAllKnowledgebaseCategories();
				if (!categories) {
					this.$store.commit('ui/showError');
					return false;
				}
				this.categories = categories;
				this.$store.commit('ui/setBreadcrumbs', {
					breadcrumbs: this.getBreadcrumbs
				});
				this.$store.commit('ui/setLoadingIsHidden');
			}
		}
	};
</script>
